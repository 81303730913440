<template>
  <v-container fluid class="pt-0">
    <template v-if="isLoading">
      <v-row class="text-h5 mt-10" justify="center" align="center">
        <v-col cols="12" class="text-center">
          <div>
            <v-progress-circular width="4"
                                 size="80"
                                 color="primary"
                                 indeterminate>
            </v-progress-circular>
          </div>
          <div class="mt-8">
            {{ $t('loadingMessages.INPROGRESS_REQUESTLOADING') }}
          </div>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <template v-if="serviceRequest.status">
        <v-row class="mt-10" justify="center" align="center">
          <v-col cols="12" class="text-center">
            <div class="text-h5 mb-8">
              {{ $t('ERROR_MESSAGES.APPLEWORKSHEET_LOADINGDATAS') }}
            </div>
            <div class="text-body-1 mb-2">
              {{ $t('errorDetails') }}:
            </div>
            <div class="text-body-2">
              {{ $t('responseStatus') }}: {{ serviceRequest.status }}
            </div>
            <div class="text-body-2">
              {{ $t('responseMessage') }}: {{ serviceRequest.message }}
            </div>
          </v-col>
        </v-row>
      </template>
      <!-- SIKERÜLT BETÖLTENI AZ IGÉNYT START -->
      <template v-else>
        <!-- MENÜSOR START -->
        <v-row class="pa-3 primary" align="center">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon color="white" class="mr-3" v-on="on" @click="backToListPage">
                <v-icon>
                  mdi-arrow-left
                </v-icon>
              </v-btn>
            </template>
            <span>
              {{ $t('button.backToList') }}
            </span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon color="white" class="mr-3"
                     v-on="on"
                     @click="getAppleShopDocument(serviceRequest.serviceRequest.id, 'shopIn')">
                <v-icon>
                  mdi-printer
                </v-icon>
              </v-btn>
            </template>
            <span>
              {{ $t('button.printAppleDocIn') }}
            </span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon color="white" class="mr-3"
                     v-on="on"
                     @click="getAppleShopDocument(serviceRequest.serviceRequest.id, 'shopOut')">
                <v-icon>
                  mdi-printer
                </v-icon>
              </v-btn>
            </template>
            <span>
              {{ $t('button.printAppleDocOut') }}
            </span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <div class="white--text text-subtitle-1">
            {{ $t('serviceRequestId') }}: {{ serviceRequest.serviceRequest.id }}
          </div>
        </v-row>
        <!-- MENÜSOR END -->
        <!-- KÖVETÉS ÉS ADATOK START -->
        <v-row>
          <!-- KÖVETÉS START -->
          <v-col cols="12" md="6" lg="4">
            <v-card class="fill-height">
              <v-card-title>
                <v-icon color="primary" class="mr-4">mdi-timeline-text-outline</v-icon>
                <span class="subtitle-1">{{ $t('tracking') }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="py-6 px-4">
                <v-list two-line class="py-0" v-if="serviceRequest.history">
                  <template v-for="(item, index) in serviceRequest.history">
                    <v-list-item class="px-0" :key="`li-${index}`">
                      <v-list-item-avatar>
                        <v-icon color="success">
                          mdi-checkbox-marked-circle
                        </v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-bold">
                          {{ item.status }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ item.date.substr(0, 10) }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider :key="`di-${index}`"></v-divider>
                  </template>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- KÖVETÉS END -->
          <!-- ESZKÖZ ADATOK START -->
          <v-col cols="12" md="6" lg="8">
            <v-card class="fill-height">
              <v-card-title>
                <v-icon color="primary" class="mr-4">mdi-devices</v-icon>
                <span class="subtitle-1">{{ $t('stepper.deviceDetails') }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="pa-6">
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="serviceRequest.serviceRequest.brand.name"
                                  :label="$t('inputLabels.manufacturer')"
                                  filled
                                  hide-details
                                  disabled>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="serviceRequest.serviceRequest.productType.tradeName"
                                  :label="$t('inputLabels.productType')"
                                  filled
                                  hide-details
                                  disabled>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="serviceRequest.serviceRequest.warrantyType.name"
                                  :label="$t('inputLabels.warrantyType')"
                                  filled
                                  hide-details
                                  disabled>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="serviceRequest.serviceRequest.uniqueIdentifierIn1"
                                  :label="$t('inputLabels.imei1')"
                                  filled
                                  hide-details
                                  disabled>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="serviceRequest.serviceRequest.uniqueIdentifierIn2"
                                  :label="$t('inputLabels.imei2')"
                                  filled
                                  hide-details
                                  disabled>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="serviceRequest.serviceRequest.uniqueIdentifierIn3"
                                  :label="$t('inputLabels.serialNr')"
                                  filled
                                  hide-details
                                  disabled>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="serviceRequest.serviceRequest.purchaseDate"
                                  :label="$t('inputLabels.dateOfPurchase')"
                                  filled
                                  hide-details
                                  disabled>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="serviceRequest.serviceRequest.purchasePrice"
                                  :label="$t('inputLabels.purchasePrice')"
                                  filled
                                  suffix="Ft"
                                  hide-details
                                  disabled>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="serviceRequest.serviceRequest.calculatedRepairPrice"
                                  :label="$t('inputLabels.repairCostLimit')"
                                  filled
                                  suffix="Ft"
                                  hide-details
                                  disabled>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- ESZKÖZ ADATOK END -->
        </v-row>
        <!-- KÖVETÉS ÉS ADATOK END -->
        <!-- SÉRÜLÉSEK START -->
        <v-row>
          <!-- FIZIKAI START -->
          <v-col cols="12" sm="6">
            <v-card class="fill-height">
              <v-card-title>
                <v-icon color="primary" class="mr-4">mdi-wrench</v-icon>
                <span class="subtitle-1">{{ $t('damages') }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="pa-6">
                <v-row>
                  <v-col cols="12">
                    <v-textarea v-model="serviceRequest.serviceRequest.physicalDamages"
                                :label="$t('physicalDamages')"
                                hide-details
                                rows="3"
                                disabled
                                filled/>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea v-model="serviceRequest.serviceRequest.aestheticFaults"
                                :label="$t('cosmeticDamages')"
                                hide-details
                                rows="3"
                                disabled
                                filled/>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- FIZIKAI END -->
          <!-- TOVÁBBI START -->
          <v-col cols="12" sm="6">
            <v-card class="fill-height">
              <v-card-title>
                <v-icon color="primary" class="mr-4">mdi-cellphone-information</v-icon>
                <span class="subtitle-1">{{ $t('additionalDatas') }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="pa-6">
                <v-row>
                  <v-col cols="12">
                    <v-textarea v-model="serviceRequest.serviceRequest.errorDescription"
                                :label="$t('inputLabels.troubleDescription')"
                                hide-details
                                rows="3"
                                disabled
                                filled>
                    </v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea v-model="serviceRequest.serviceRequest.accessories"
                                :label="$t('inputLabels.accessories')"
                                hide-details
                                rows="3"
                                disabled
                                filled>
                    </v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- TOVÁBBI END -->
        </v-row>
        <!-- SÉRÜLÉSEK END -->
        <!-- ÜGYFÉL ADATOK START -->
        <v-row>
          <v-col cols="12">
            <v-card class="fill-height">
              <v-card-title>
                <v-icon color="primary" class="mr-4">mdi-account-circle</v-icon>
                <span class="subtitle-1">{{ $t('stepper.customerDetails') }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="pa-6">
                <v-row>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <v-text-field v-model="serviceRequest.customer.lastName"
                                  :label="$t('inputLabels.lastName')"
                                  hide-details
                                  filled
                                  disabled>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <v-text-field v-model="serviceRequest.customer.firstName"
                                  :label="$t('inputLabels.firstName')"
                                  hide-details
                                  filled
                                  disabled>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <v-text-field v-model="serviceRequest.customer.email"
                                  :label="$t('inputLabels.email')"
                                  hide-details
                                  filled
                                  disabled>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <v-text-field v-model="serviceRequest.customer.phone"
                                  :label="$t('inputLabels.phone')"
                                  hide-details
                                  prefix="+36-"
                                  filled
                                  disabled>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <!-- ÜGYFÉL ADATOK END -->
        <!-- CÍMEK START -->
        <v-row>
          <!-- SZÁLLÍTÁSI ADATOK START -->
          <v-col cols="12" lg="6">
            <v-card class="fill-height">
              <v-card-title>
                <v-icon color="primary" class="mr-4">mdi-truck-fast-outline</v-icon>
                <span class="subtitle-1">{{ $t('deliveryAddress') }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : 'pa-6'">
                <v-row>
                  <v-col cols="12">
                    <v-text-field v-model="serviceRequest.shippingAddressData.billingName"
                                  :label="$t('shippingName')"
                                  hide-details
                                  filled
                                  disabled>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="shippingAddressString"
                                  :label="$t('deliveryAddress')"
                                  hide-details
                                  filled
                                  disabled>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea v-model="serviceRequest.shippingAddressData.otherAddressInfo"
                                :label="$t('otherDescription')"
                                hide-details
                                filled
                                rows="3"
                                disabled>
                    </v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- SZÁLLÍTÁSI ADATOK END -->
          <!-- SZÁMLÁZÁSI ADATOK START -->
          <v-col cols="12" lg="6">
            <v-card class="fill-height">
              <v-card-title>
                <v-icon color="primary" class="mr-4">mdi-receipt</v-icon>
                <span class="subtitle-1">{{ $t('invoicingAddress') }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : 'pa-6'">
                <v-row>
                  <v-col cols="12">
                    <v-text-field v-model="serviceRequest.billingAddressData.billingName"
                                  :label="$t('invoicingName')"
                                  hide-details
                                  filled
                                  disabled>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="billingAddressString"
                                  :label="$t('invoicingAddress')"
                                  hide-details
                                  filled
                                  disabled>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="serviceRequest.billingAddressData.taxNumber"
                                  :label="$t('vatNumber')"
                                  hide-details
                                  filled
                                  disabled>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field v-model="serviceRequest.billingAddressData.otherAddressInfo"
                                  :label="$t('otherDescription')"
                                  hide-details
                                  filled
                                  disabled>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- SZÁMLÁZÁSI ADATOK END -->
        </v-row>
        <!-- CÍMEK END -->
        <!-- DOKUMENTUMOK START -->
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title>
                <v-icon color="primary" class="mr-4">mdi-file-document-outline</v-icon>
                <span class="subtitle-1">{{ $t('documents') }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : 'pa-6'">
                <v-row>
                  <template v-for="(document, index) in serviceRequest.documents">
                    <v-col col="12" sm="4" :key="index" v-if="document.hash && document.table">
                      <v-card>
                        <v-card-text class="pa-0">
                          <v-list two-line>
                            <v-list-item>
                              <v-list-item-avatar>
                                <v-avatar color="primary">
                                  <v-icon color="white">
                                    mdi-pdf-box
                                  </v-icon>
                                </v-avatar>
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title class="">
                                  {{ document.description }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                  {{ document.createdAt }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card-text>
                        <v-card-actions class="px-3 pb-3 pt-0">
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="downloadFile(document)">
                            {{ $t('button.download') }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </template>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <!-- DOKUMENTUMOK END -->
      </template>
      <!-- SIKERÜLT BETÖLTENI AZ IGÉNYT END -->
    </template>
    <!-- LAR DIALOG START -->
    <loading-and-response-dialog :element-props.sync="loadingAndResponseDialog"
                                 v-on:on-error-click="onErrorClickInLARDialog"
                                 v-on:on-success-click="onSuccussCickInLARDialog"></loading-and-response-dialog>
    <!-- LAR DIALOG END -->
  </v-container>
</template>

<script>
import LoadingAndResponseDialog from 'src/components/dialogs/LoadingAndResponseDialog.vue';

export default {
  name: 'AppleRequestDetailsPage',
  components: {
    LoadingAndResponseDialog,
  },
  props: {
    //
  },
  async beforeMount() {
    if (this.$route.params.ws) {
      this.serviceRequest = await this.getAppleWorksheetDetails(this.$route.params.ws);
      this.serviceRequest.history = await this.getAppleWorksheetHistory(this.$route.params.ws);
      this.serviceRequest.documents = await this.getAppleWorksheetDocuments(this.$route.params.ws);
      await this.getAppleWorksheetAdditionalDocuments(this.$route.params.ws);
      this.isLoading = false;
    } else {
      this.$router.push({ name: 'apple-munkalap' });
    }
  },
  data() {
    return {
      billingAddressString: '',
      isLoading: true,
      loadingAndResponseDialog: {
        isVisible: false,
        stepper: 1,
        loadingText: 'loadingMessages.INPROGRESS_FILEDOWNLOAD',
        successText: 'SUCCESS_MESSAGES.APPLEWORKSHEET_SAVE',
        errorText: 'ERROR_MESSAGES.DOWNLOAD_FILE',
        successBtnText: 'button.ok',
        errorBtnText: 'button.ok',
      },
      serviceRequest: {},
      shippingAddressString: '',
    };
  },
  methods: {
    backToListPage() {
      this.$router.push({ name: 'apple-munkalap' });
    },
    async downloadFile(document) {
      // console.log('document: ', document);
      if (document !== {}) {
        let fileHandler = {
          crmRequestId: this.serviceRequest.serviceRequest.id,
          table: document.table,
          hash: document.hash,
        }
        const respone = await this.$store.dispatch('downloadDocument', { form: fileHandler });
      } else {
        this.downloadFileErrorDialog.visible = true;
      }
    },
    async getAppleWorksheetDetails(id) {
      const response = await this.$store.dispatch('getRequestById', id);
      if (response.status === 200) {
        this.shippingAddressString = `${response.data.shippingAddressData.zipCode} ${response.data.shippingAddressData.city} ${response.data.shippingAddressData.publicPlaceName} ${response.data.shippingAddressData.publicPlaceType} ${response.data.shippingAddressData.houseNumber}`;
        this.billingAddressString = `${response.data.billingAddressData.zipCode} ${response.data.billingAddressData.city} ${response.data.billingAddressData.publicPlaceName} ${response.data.billingAddressData.publicPlaceType} ${response.data.billingAddressData.houseNumber}`;
        if (response.data.shippingAddressData.billingName.length < 1) {
          response.data.shippingAddressData.billingName = `${response.data.customer.lastName} ${response.data.customer.firstName} - ${this.$t('deliveryInStore')}`;
        }
        return response.data;
      }
      return {
        status: response.status,
        message: response.message,
      };
    },
    async getAppleWorksheetHistory(id) {
      const response = await this.$store.dispatch('getRequestHistoryById', id);
      if (response.status === 200) {
        return response.data;
      }
      return [];
    },
    async getAppleWorksheetDocuments(id) {
      const response = await this.$store.dispatch('getRequestDocumentsById', id);
      if (response.status === 200) {
        return response.data;
      }
      return [];
    },
    async getAppleWorksheetAdditionalDocuments(id) {
      const response = await this.$store.dispatch('GetAppleDocuments', id);
      if (response.status === 200) {
        for (let i = 0; i < response.data.length; i++) {
          this.serviceRequest.documents.push(response.data[i]);
        }
      }
    },
    async getAppleShopDocument(id, docType) {
      this.openLARDialog();
      const response = await this.$store.dispatch('DownloadAppleShopDocuments', { id: id, docType: docType });
      if (response) {
        this.closeLARDialog();
      } else {
        this.changeLARDialogContentToError();
      }
    },
    /**
     *
     * Loading and response dialog
     *
     */
    openLARDialog() {
      this.loadingAndResponseDialog.stepper = 1;
      this.loadingAndResponseDialog.isVisible = true;
    },
    closeLARDialog() {
      this.loadingAndResponseDialog.isVisible = false;
    },
    changeLARDialogContentToSuccess() {
      this.loadingAndResponseDialog.stepper = 2;
    },
    changeLARDialogContentToError() {
      this.loadingAndResponseDialog.stepper = 3;
    },
    onSuccussCickInLARDialog() {
      this.closeLARDialog();
    },
    onErrorClickInLARDialog() {
      this.closeLARDialog();
    },
  },
};
</script>

<style scoped>

</style>
