import { render, staticRenderFns } from "./AppleRequestDetailsPage.vue?vue&type=template&id=dac02e0e&scoped=true&"
import script from "./AppleRequestDetailsPage.vue?vue&type=script&lang=js&"
export * from "./AppleRequestDetailsPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dac02e0e",
  null
  
)

export default component.exports